@import "https://fonts.googleapis.com/css2?family=Fira+Code:wght@400;700&display=swap";
.w-layout-grid {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

body {
  font-family: 'Fira Code', monospace;
  color: #040515;
  font-size: 18px;
  line-height: 1.5em;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 38px;
  line-height: 1.1em;
  font-weight: 700;
}

h2 {
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 3em;
  line-height: 1.2em;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-size: 1.7em;
  line-height: 30px;
  font-weight: 700;
}

a {
  color: #040515;
  font-weight: 700;
  text-decoration: none;
}

li {
  padding-bottom: 8px;
}

.hero-letters {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.hero-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fd5769;
  color: #fff;
  text-align: center;
}

.hero-section.ff-hero {
  background-color:  transparent;
}

.hero-heading {
  position: relative;
  z-index: 1;
  margin-top: 0px;
  letter-spacing: 0.15em;
  margin-bottom: 0px;
  font-size: 16vw;
  text-shadow: -17px 17px 0 #040515;
  cursor: crosshair;
}

.hero-heading:hover {
  color: #FF00FD;
}

#pfp-file-container { display: none; }

.hero-heading._2 {
  z-index: 2;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  color: #fff;
  font-size: 5.5rem;
  transition: letter-spacing 0.2s ease;
  text-shadow: 10px 7px 0 rgba(0,0,0,0.08), -7px -10px 0 rgba(0,0,0,0.17);
}

.hero-heading._2:hover {
  letter-spacing: 0.5em;
  color: #fff;
}

.hero-heading._3 {
  z-index: 3;
}

.hero-heading._3:hover {
  color: hsl(322, 69%, 57%);
}

.primary-button {
  min-width: 175px;
  padding: 16px 40px;
  border-style: solid;
  border-width: 4px;
  border-color: #000;
  border-radius: 8px;
  background-color: transparent;
  box-shadow: -2px 2px 0 0 hsl(322, 69%, 57%);
  -webkit-transition: background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease;
  transition: background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  font-family: 'Fira Code', monospace;
  color: #fff;
  font-size: 1.25em;
  text-align: center;
  letter-spacing: 0.12em;
}

.primary-button:hover {
  background-color: #FFF;
  box-shadow: -6px 6px 0 0 #040515;
  -webkit-transform: translate(4px, -4px);
  -ms-transform: translate(4px, -4px);
  transform: translate(4px, -4px);
}

.primary-button.hero {
  z-index: 1000;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 20px 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-width: 8px;
  border-radius: 12px;
  border: 2px solid #000;
  background-color: transparent;
  box-shadow: -6px 6px 0 0 rgba(0,0,0,0.3);
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  color: #000;
  font-size: 1.5em;
}

.primary-button.hero:hover {
  position: relative;
  z-index: 4;
  background-color: #FFF;
  box-shadow: -11px 11px 0 0 #000;
  color: #040515;
}

.primary-button.hero:active {
  box-shadow: -6px 6px 0 0 #040515;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.primary-button.footer {
  padding: 20px 32px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-width: 4px;
  border-color: #32016E;
  background-color: #503888;
  box-shadow: -6px 6px 0 0 #32016E;
  font-size: 1.5em;
}

.primary-button.footer:hover {
  background-color: #ff0d11;
}

.nav-bar {
  position: fixed;
  left: 0%;
  top: 12px;
  right: 0%;
  bottom: auto;
  z-index: 9999;
  display: none;
  max-width: 580px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 12px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 16px;
  background-color: #040515;
}

.nav-bar.license {
  max-width: 420px;
}

.nav-bar.your-stuff {
  max-width: 520px;
}

.link-block {
  padding: 20px 16px;
  color: #fff;
  text-decoration: none;
}

.link-block:hover {
  color: #f9e174;
}

.link-block.w--current {
  color: #c6dd42;
}

.link-block.last {
  padding-right: 24px;
}

.button {
  border-radius: 8px;
  background-color: #ff4265;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}

.button:hover {
  background-color: #3bb4ff;
}

.button.nav {
  margin-left: 8px;
}

.mint-section {
  position: relative;
  padding: 120px 24px 100px;
  background-color: #00ff15;
  background-image: url('skies-2.5ac05b77.png');
  background-position: 0px 0px;
  background-size: auto;
}

.total-skies {
  margin-top: 24px;
  padding: 12px 16px;
  border-radius: 6px;
  background-color: #f3f2ed;
  color: rgba(31, 32, 36, 0.8);
  font-size: 0.9em;
  line-height: 1.5em;
  letter-spacing: 1px;
}

.minting-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 700px;
  padding: 58px 58px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: dashed;
  border-width: 6px;
  border-color: rgba(0,0,0,0.5);
  border-radius: 24px;
  background-color: #fff;
  box-shadow: -3px 3px 0 0 rgba(0,0,0,0.75);
  text-align: center;
}

.pfp-dragging-over .minting-container {
  border-color: #00c;
}

.image {
  position: absolute;
  left: -20px;
  top: auto;
  right: auto;
  bottom: -20px;
  z-index: 1;
  width: 14vw;
  max-width: 300px;
  min-width: 200px;
  -webkit-transform: rotate(-23deg);
  -ms-transform: rotate(-23deg);
  transform: rotate(-23deg);
}

.image.bl1 {
  left: 10vw;
  bottom: 21%;
  min-width: 200px;
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

.image.cl2 {
  left: 18%;
  top: 23%;
  bottom: auto;
  width: 16vw;
  -webkit-transform: rotate(-18deg);
  -ms-transform: rotate(-18deg);
  transform: rotate(-18deg);
}

.image.cl1 {
  left: -1%;
  top: 20%;
  bottom: auto;
  z-index: 0;
  min-width: 200px;
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

.image.tl {
  left: 12%;
  top: -3%;
  bottom: auto;
  z-index: 0;
  width: 12vw;
  min-width: 225px;
  margin-left: -100px;
  padding-left: 0px;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
}

.image.bc {
  left: auto;
  min-width: 250px;
  margin-bottom: -20px;
  -webkit-transform: rotate(-11deg);
  -ms-transform: rotate(-11deg);
  transform: rotate(-11deg);
}

.image.br {
  left: auto;
  right: 0px;
  min-width: 250px;
  -webkit-transform: rotate(14deg);
  -ms-transform: rotate(14deg);
  transform: rotate(14deg);
}

.image.br1 {
  left: auto;
  right: 10vw;
  bottom: 25%;
  min-width: 325px;
  margin-top: 15px;
  margin-right: -50px;
  margin-bottom: -30px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  -webkit-transform: scale(0.8) rotate(-14deg);
  -ms-transform: scale(0.8) rotate(-14deg);
  transform: scale(0.8) rotate(-14deg);
}

.image.cr2 {
  left: auto;
  top: 23%;
  right: 24%;
  bottom: auto;
  z-index: 2;
  -webkit-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}

.image.tr {
  left: auto;
  top: -2%;
  right: 12%;
  bottom: auto;
  z-index: 0;
  width: 12vw;
  min-width: 200px;
  -webkit-transform: rotate(8deg);
  -ms-transform: rotate(8deg);
  transform: rotate(8deg);
}

.image.cr1 {
  left: auto;
  top: 18%;
  right: -1%;
  bottom: auto;
  z-index: 6;
  min-width: 200px;
  -webkit-transform: rotate(20deg);
  -ms-transform: rotate(20deg);
  transform: rotate(20deg);
}

.hero-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  padding-left: 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  background-image: url(bg-skies.90bbe15f.png);
  background-size: cover;
  background-position:  center;
  color: #fff;
}

.hide {
  display:  none;
}

.ff-hero .hero-container {
  background-color:  transparent; 
  background-image:  url(founder-fudders-hero.f4bcb71c.png);
  background-size:  cover;
  background-position:  center;
}

.intermission {
  position: relative;
  margin-bottom: -100px;
  background-color: #040515;
}

.center-image-block {
  position: relative;
  top: -100px;
}

.center-image-block._2 {
  top: -100px;
  margin-top: -100px;
}

.image-3 {
  margin-bottom: -100px;
}

.new-label {
  font-size: 0.6em;
  line-height: 0.6em;
  position: relative;
  top: -5px;
  left: -3px;
  display: inline-flex;
  padding: 2px;
  height: 10px;
  color: #000;
  background: white;
}

.rarity-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100.0000021569098%;
  padding: 120px 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 16px;
  border-color: #040515;
  background-color: #163892;
  background-image: url('pixel-art-farm-vector-19072159.392509c8.jpg'), url('diagrams.ac53af23.png');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.rarity-chart {
  position: relative;
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 740px;
  padding: 32px;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto auto auto;
  border-style: solid;
  border-width: 8px;
  border-color: #040515;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: -11px 11px 0 -4px #040515;
}

.rarity-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
}

.rarity-row.blue {
  background-color: #cae9fa;
}

.rarity-number {
  width: 50%;
  font-weight: 700;
}

.rarity-heading {
  font-size: 1em;
}

.faq-section {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 200px 24px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #FFF;
  background-image:  url(skys-faq.c2d0ec81.png);
  background-size:  cover;
  background-attachment:  fixed;
  background-position:  center;
  text-shadow: 2px 2px 0 rgba(255, 255, 255, 0.7)
}

.question {
  padding-top: 20px;
  padding-bottom: 20px;
}

.question.last {
  padding-bottom: 0px;
}

.question.first {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 0px;
}

.faq-container {
  max-width: 720px;
}

.footer-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 16px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #040515;
  color: #fff;
  text-align: center;
}

.domo-text {
  font-family: 'Right Grotesk', sans-serif;
  font-size: 16vw;
  line-height: 0.8em;
}

.domo-text:hover {
  color: #f9e174;
}

.domo-text.purple {
  padding-bottom: 32px;
}

.domo-text.purple:hover {
  color: #a774f9;
}

.follow-test {
  padding: 16px;
}

.bold-white-link {
  color: #fff;
}

.bold-white-link:hover {
  color: #f9e174;
}

.faq-left {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.faq-img {
  max-width: 160px;
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

.faq-img._1 {
  -webkit-transform: translate(32px, 0px) rotate(10deg);
  -ms-transform: translate(32px, 0px) rotate(10deg);
  transform: translate(32px, 0px) rotate(10deg);
}

.faq-img._2 {
  -webkit-transform: translate(-32px, 0px) rotate(-10deg) rotate(20deg);
  -ms-transform: translate(-32px, 0px) rotate(-10deg) rotate(20deg);
  transform: translate(-32px, 0px) rotate(-10deg) rotate(20deg);
}

.faq-img._3 {
  -webkit-transform: translate(0px, 0px) rotate(-5deg);
  -ms-transform: translate(0px, 0px) rotate(-5deg);
  transform: translate(0px, 0px) rotate(-5deg);
}

.faq-right {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.faq-bottom {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: -24px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 68%;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.faq-top {
  position: absolute;
  left: auto;
  top: -24px;
  right: auto;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 68%;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.rarity-image {
  padding: 16px;
}

.div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.mint-card-image {
  cursor: pointer;
  width: 200px;
  height: 200px;
  margin: -120px auto 16px;
  background: #999;
  border: 3px dashed rgba(0,0,0,0.8);
  border-radius: 100px;
  overflow: hidden;
  position: relative;
  user-select: none;
}

#selected-preview-bg {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.mint-card-image:hover {
  border-color: #00f;
}

.pfp-dragging-over .mint-card-image {
  border-color: #00f;
}

.mint-card-image img {
  border-radius: 100px;
  transition: 0.2s all ease;
}

.mint-card-image .drop-here-text {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 20px;
  padding: 10px 0;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.25);
  color: #fff;
  text-align: center;
  font-weight: 700;
  text-shadow: 1px 1px 1px #000;
}

.if-skies, .no-skies {
  display: none;
}

.with-image-preview .mint-card-image .drop-here-text,
.with-image-preview .mint-card-image svg {
  transition: 0.2s opacity ease;
  opacity: 0;
}

.with-image-preview .mint-card-image:hover .drop-here-text,
.with-image-preview .mint-card-image:hover svg {
  opacity: 1;
}

.pfp-dragging-over .mint-card-image img {
  filter: hue-rotate(15deg);
}

.if-connected,
.if-not-connected {
  display: none;
}

.mint-card-image svg {
  width: 100%;
  position: absolute;
  top: 30%;
  fill: rgba(0,0,0,0.5);
  left: 0;
}

.heading-2 {
  margin-bottom: 0px;
}

.paragraph {
  margin-bottom: 24px;
  font-weight: 400;
}

.hero-price {
  position: relative;
  z-index: 999;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
}

.your-roboto-name {
  margin-top: 16px;
  margin-bottom: 0px;
  font-size: 1.5em;
}

.your-roboto-card {
  overflow: hidden;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 12px 16px;
  border-style: solid;
  border-width: 4px;
  border-color: #040515;
  border-radius: 20px;
}

.your-roboto-card:hover {
  box-shadow: -6px 6px 0 0 #040515;
  -webkit-transform: translate(4px, -4px);
  -ms-transform: translate(4px, -4px);
  transform: translate(4px, -4px);
}

.your-roboto-image {
  padding: 12px;
  border-style: solid;
  border-width: 2px;
  border-color: #c9c9c9;
  border-radius: 12px;
}

.yourstuff-container {
  display: -ms-grid;
  display: grid;
  max-width: 1200px;
  padding: 58px 58px 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  border-style: solid;
  border-width: 8px;
  border-color: #040515;
  border-radius: 24px;
  background-color: #fff;
  box-shadow: -3px 3px 0 0 #040515;
  text-align: center;
}

.your-roboto-asseet {
  overflow: hidden;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 12px 12px 16px;
  border-style: none;
  border-width: 4px;
  border-color: #040515;
  border-radius: 20px;
}

.your-roboto-card-image {
  border-radius: 12px;
}

.nav-link {
  padding: 16px;
  color: #fff;
}

.nav-link.w--current {
  color: #15bdff;
}

.nav-link.w--current .new-label {
  background: #15bdff;  
}

.nav-link.logo {
  padding-left: 8px;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.4em;
}

.brand {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 16px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid #494949;
  border-radius: 8px 0px 0px 8px;
  background-color: #040515;
  color: #fff;
  min-width: max-content;
}

.navbar {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  background-color: transparent;
}

#selected-pfp-preview {
  object-fit: cover;
  margin-top: -220px;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px 8px 8px 0px;
  background-color: #040515;
  color: #fff;
}

.container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-6 {
  margin-right: 0px;
}

.section {
  position: relative;
  display: -ms-grid;
  display: grid;
  overflow: hidden;
  width: 100%;
  padding: 96px 32px 32px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  justify-items: stretch;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1.5fr;
  grid-template-columns: 1fr 1.5fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  background-color: #f9e174;
}

.section.bw {
  background-color: transparent;
}

.parts-subtitle {
  font-family: 'Space Mono', sans-serif;
  font-size: 1.25em;
  font-weight: 400;
  text-transform: none;
}

.heading-3 {
  margin-top: 0px;
  margin-bottom: 8px;
  font-family: 'Right Grotesk', sans-serif;
  font-size: 2.25em;
}

.spec-grid {
  display: -ms-grid;
  display: grid;
  height: 100%;
  max-width: 800px;
  padding: 24px;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-row-align: center;
  align-self: center;
  grid-auto-flow: row dense;
  grid-auto-columns: 1fr;
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  -ms-grid-columns: 1fr 2fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-style: solid;
  border-width: 8px;
  border-color: #040515;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.1);
}

.spec-details {
  position: -webkit-sticky;
  position: sticky;
  left: 0px;
  top: 0px;
  bottom: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 88vh;
  padding-right: 32px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.tech-part-container {
  text-align: center;
}

.tech-part-container.bw {
  -webkit-filter: brightness(131%) grayscale(100%) contrast(200%);
  filter: brightness(131%) grayscale(100%) contrast(200%);
}

.tech-description {
  padding-top: 4px;
}

.download {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 32px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 520px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  border-radius: 16px;
  background-color: #fff;
}

.tech-message {
  padding-bottom: 8px;
}

.spec-grid-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 800px;
  padding: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  align-self: center;
  grid-auto-flow: row dense;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 2fr 1fr;
  grid-template-columns: 1fr 2fr 1fr;
  -ms-grid-rows: auto auto auto;
  grid-template-rows: auto auto auto;
  border-style: solid;
  border-width: 8px;
  border-color: #040515;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.1);
}

.spec-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.tech-part-container-row {
  margin: 16px;
  text-align: center;
}

.heading {
  position: fixed;
  width: 100%;
}

.bw {
  -webkit-filter: contrast(163%) grayscale(100%) brightness(108%);
  filter: contrast(163%) grayscale(100%) brightness(108%);
}

.primary-button-2 {
  max-width: 175px;
  min-width: 175px;
  margin-right: 40px;
  margin-left: 40px;
  padding: 16px 40px 16px 20px;
  border-style: solid;
  border-width: 4px;
  border-color: #040515;
  border-radius: 8px;
  background-color: #ff55a2;
  box-shadow: -3px 3px 0 0 #040515;
  -webkit-transition: background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease;
  transition: background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  font-family: 'Right Grotesk', sans-serif;
  color: #fff;
  font-size: 1.25em;
  text-align: left;
  letter-spacing: 0.12em;
}

.primary-button-2:hover {
  background-color: #e42c7f;
  box-shadow: -6px 6px 0 0 #040515;
  -webkit-transform: translate(4px, -4px);
  -ms-transform: translate(4px, -4px);
  transform: translate(4px, -4px);
}

.primary-button-2.hero {
  z-index: 1000;
  margin-top: 16px;
  margin-bottom: 24px;
  padding: 20px 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-width: 8px;
  border-radius: 12px;
  background-color: #ace5d7;
  box-shadow: -6px 6px 0 0 #040515;
  -webkit-transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease;
  transition: color 200ms ease, background-color 200ms ease, transform 200ms ease, box-shadow 200ms ease, -webkit-transform 200ms ease;
  color: #040515;
  font-size: 1.5em;
}

.primary-button-2.hero:hover {
  position: relative;
  z-index: 4;
  background-color: #ff00a1;
  box-shadow: -11px 11px 0 0 #040515;
  color: #fff;
}

.primary-button-2.hero:active {
  box-shadow: -6px 6px 0 0 #040515;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.primary-button-2.footer {
  padding: 20px 32px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  border-width: 4px;
  border-color: #32016E;
  background-color: #503888;
  box-shadow: -6px 6px 0 0 #32016E;
  font-size: 1.5em;
}

.primary-button-2.footer:hover {
  background-color: #FFF;
}

.heading-4 {
  margin-top: 0px;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  text-align: center;
}

.heading-4.stake.staking {
  margin-bottom: 20px;
}

.pfp-dragging-over .heading-4 {
  color: #00c; 
}

.grid {
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.grid-2 {
  -ms-grid-columns: 0.5fr 1.25fr 0.25fr 0.5fr 1.25fr;
  grid-template-columns: 0.5fr 1.25fr 0.25fr 0.5fr 1.25fr;
  -ms-grid-rows: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.text-block-9 {
  font-size: 16px;
}

.text-block-10 {
  font-weight: 400;
}

.italic-text {
  font-weight: 700;
  white-space: normal;
}

.heading-5 {
  margin-top: -30px;
  margin-bottom: 20px;
  font-family: Frutiger, "Frutiger Linotype", "Dejavu Sans", "Helvetica Neue", Arial, sans-serif;
  text-align: center;
}

.faq {
  margin-top: -100px;
  padding-bottom: 12px;
}

.paragraph-2 {
  margin-bottom: 20px;
}

.mint-info-container {
  background-color:  040515;
  border:  2px solid #1BF4F6

}

.stake-crop-container .primary-button {
    color: black;
    border: 2px solid black;
}

@media screen and (max-width: 991px) {
  body {
    font-size: 14px;
  }

  .hero-heading {
    text-shadow: -11px 11px 0 #040515;
  }

  .mint-section {
    overflow: hidden;
  }

  .total-skies {
    font-size: 1em;
  }

  .minting-container {
    padding: 40px;
  }

  .image {
    max-width: 200px;
    min-width: 140px;
  }

  .rarity-section {
    overflow: hidden;
  }

  .rarity-chart {
    width: 100%;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .faq-left {
    display: none;
  }

  .faq-img {
    max-width: 120px;
  }

  .faq-right {
    display: none;
  }

  .faq-bottom {
    bottom: 0px;
    width: 100%;
  }

  .faq-top {
    top: 0px;
    width: 100%;
  }

  .rarity-image {
    max-width: 320px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
  }

  .yourstuff-container {
    padding: 40px;
  }

  .container {
    max-width: 530px;
  }

  .section {
    padding-top: 88px;
  }

  .download {
    position: relative;
    width: 100%;
    max-width: none;
  }

  .spec-grid-flex {
    max-width: none;
  }

  .heading {
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .hero-letters {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .hero-section {
    height: auto;
  }

  .hero-heading {
    margin-top: 0px;
    font-size: 37vw;
    line-height: 0.75em;
    text-shadow: -8px 8px 0 #040515;
  }

  .primary-button.hero {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }

  .button.nav {
    width: 100%;
    margin-left: auto;
  }

  .minting-container {
    padding-right: 24px;
    padding-left: 24px;
    border-width: 6px;
  }

  .image {
    max-width: 180px;
    min-width: 120px;
  }

  .image.cl2 {
    top: 10%;
  }

  .image.cr2 {
    top: 10%;
  }

  .hero-container {
    padding-top: 220px;
    padding-bottom: 220px;
  }

  .intermission {
    display: none;
  }

  .center-image-block {
    top: auto;
  }

  .center-image-block._2 {
    position: relative;
    top: auto;
  }

  .rarity-section {
    padding-right: 16px;
    padding-left: 16px;
    border-style: none;
  }

  .rarity-chart {
    padding: 24px;
  }

  .faq-img {
    max-width: 100px;
  }

  .faq-img._1 {
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
  }

  .faq-img._3 {
    max-width: 80px;
  }

  .faq-bottom {
    bottom: 24px;
  }

  .faq-top {
    top: 24px;
  }

  .rarity-image {
    width: 100%;
  }

  .yourstuff-container {
    padding-right: 24px;
    padding-left: 24px;
    border-width: 4px;
  }

  .nav-link {
    padding-left: 0px;
  }

  .nav-link.logo {
    display: none;
  }

  .brand {
    padding-top: 12px;
    padding-right: 16px;
    padding-bottom: 12px;
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
    border-radius: 8px;
  }

  .nav-menu {
    margin-right: 8px;
    margin-left: 8px;
    padding-right: 16px;
    padding-bottom: 16px;
    padding-left: 16px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-radius: 8px;
    background-color: #040515;
  }

  .container {
    max-width: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 8px;
  }

  .image-6 {
    margin-right: 0px;
  }

  .menu-button {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    border-radius: 16px;
    background-color: #000;
  }

  .menu-button.w--open {
    background-color: #ff3979;
  }

  .icon {
    color: #fff;
  }

  .section {
    height: auto;
    padding-right: 24px;
    padding-left: 24px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    text-align: center;
  }

  .spec-grid {
    width: 100%;
  }

  .spec-details {
    width: auto;
    height: auto;
    padding-right: 0px;
    padding-bottom: 24px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .download {
    bottom: auto;
    margin-top: 32px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .primary-button-2.hero {
    padding-right: 0px;
    padding-left: 0px;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
  }
}

@media screen and (max-width: 479px) {
  h2 {
    font-size: 2em;
  }

  .nav-bar {
    margin-right: 16px;
    margin-left: 16px;
  }

  .link-block {
    padding-right: 12px;
    padding-left: 12px;
  }

  .link-block.w--current {
    display: none;
  }

  .link-block.rarity {
    display: none;
  }

  .mint-section {
    padding-right: 12px;
    padding-left: 12px;
  }

  .minting-container {
    padding-right: 16px;
    padding-left: 16px;
  }

  .image {
    max-width: 160px;
    min-width: 100px;
  }

  .rarity-section {
    padding: 48px 16px;
    background-color: #fff;
    background-image: none;
  }

  .rarity-chart {
    padding: 0px;
    border-style: none;
    box-shadow: none;
  }

  .faq-section {
    padding-right: 16px;
    padding-left: 16px;
  }

  .faq-container {
    max-width: 100%;
  }

  .heading-2 {
    text-align: center;
  }

  .text-block-8 {
    display: none;
  }

  .your-roboto-name {
    margin-top: 8px;
    font-size: 1em;
  }

  .your-roboto-card {
    padding: 9px;
  }

  .yourstuff-container {
    padding-right: 8px;
    padding-left: 8px;
  }

  .license-container {
    max-width: 100%;
  }

  .your-roboto-asseet {
    padding: 9px;
  }

  .section {
    padding-right: 16px;
    padding-left: 16px;
  }

  .spec-grid {
    padding: 12px;
  }

  .spec-grid-flex {
    padding: 12px;
  }
}

#w-node-a6af6ef0-8075-5cd3-3b40-dc2de848e992-2cceedbb {
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
}

#w-node-_17b516e0-1675-4983-56c1-2d517321fe6f-b2ceedc0 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4eef881a-8a0b-0b7f-a192-eb158e5ccf85-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-bf085115-9fb5-3934-bff3-ad7151a4386d-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_85905fbe-2c73-c15b-c60d-e0830d14d00e-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_8d64b1d1-cddd-b0e5-304f-f903cbdf8788-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
}

#w-node-b969b4fb-9f87-69be-8f93-2b5531b9e672-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_9f6830b4-ae79-ba78-10b0-328f2706e12e-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_6a384503-06be-4214-550a-98e07b1dddbd-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
}

#w-node-_0377931e-9235-e83f-5836-784462ab588e-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
}

#w-node-_308f2ed7-91b7-28f4-6bca-1980e8bc4d38-b2ceedc0 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
  -ms-grid-row: 5;
  grid-row-start: 5;
}

#w-node-_17b516e0-1675-4983-56c1-2d517321fe6f-2aceedc1 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_4eef881a-8a0b-0b7f-a192-eb158e5ccf85-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

#w-node-bf085115-9fb5-3934-bff3-ad7151a4386d-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_85905fbe-2c73-c15b-c60d-e0830d14d00e-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3;
  -ms-grid-row: 2;
  grid-row-start: 2;
}

#w-node-_8d64b1d1-cddd-b0e5-304f-f903cbdf8788-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
}

#w-node-b969b4fb-9f87-69be-8f93-2b5531b9e672-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_9f6830b4-ae79-ba78-10b0-328f2706e12e-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 4;
  -ms-grid-row: 3;
  grid-row-start: 3;
}

#w-node-_6a384503-06be-4214-550a-98e07b1dddbd-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
}

#w-node-_0377931e-9235-e83f-5836-784462ab588e-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-column: 3;
  grid-column-start: 3;
  -ms-grid-row-span: 1;
  grid-row-end: 5;
  -ms-grid-row: 4;
  grid-row-start: 4;
}

#w-node-_308f2ed7-91b7-28f4-6bca-1980e8bc4d38-2aceedc1 {
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-column: 2;
  grid-column-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 6;
  -ms-grid-row: 5;
  grid-row-start: 5;
}

#w-node-_17b516e0-1675-4983-56c1-2d517321fe6f-59ceedc2 {
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_6d743476-bb55-2d25-b564-f9beec69d729-b4ceedc3 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a00e1cff-2567-d0e3-b0c0-e8ab48c8d61d-b4ceedc3 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_76d4a55e-a337-ab32-3f71-987af0777d6e-b4ceedc3 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-a9e9451d-36e0-c4c6-24ae-e19a051986c1-b4ceedc3 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
  -ms-grid-column-align: center;
  justify-self: center;
}

#w-node-_3e50bec3-cf5b-6def-b081-8f21cf3bae2f-b4ceedc3 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6d743476-bb55-2d25-b564-f9beec69d729-77ceedc4 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a00e1cff-2567-d0e3-b0c0-e8ab48c8d61d-77ceedc4 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-_6d743476-bb55-2d25-b564-f9beec69d729-dcceedc5 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-a00e1cff-2567-d0e3-b0c0-e8ab48c8d61d-dcceedc5 {
  -ms-grid-column: span 2;
  grid-column-start: span 2;
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
/*# sourceMappingURL=index.07fdd785.css.map */
